import {
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USERS_BY_ID_SUCCESS,
  GET_USERS_BY_ID_FAIL,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAIL,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAIL,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  VERIFY_USERS_FAIL,
  VERIFY_USERS_SUCCESS,
  GET_DELETED_USERS_SUCCESS,
  GET_DELETED_USERS_FAIL,
  DELETE_USERS_REQUEST_SUCCESS,
  DELETE_USERS_REQUEST_FAIL,
  UPDATE_DELETE_SUCCESS,
  FREEZE_USERS_SUCCESS,
  FREEZE_USERS_FAIL,
  REJECT_USERS_SUCCESS,
  REJECT_USERS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  usersById: [],
  addUpdateSuccess: null,
  deleteSuccess: null,
  deletedUsers: [],
  error: {},
  freezeSuccess: null,
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
        error: {},
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: null,
        deleteSuccess: null,
      }

    case GET_USERS_BY_ID_SUCCESS:
      return {
        ...state,
        usersById: action.payload,
        error: {},
      }

    case GET_USERS_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        usersById: [],
      }

    case ADD_USERS_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        error: {},
        addUpdateSuccess: true,
      }

    case ADD_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        users: state.users.map(users =>
          users.id === action.payload.id ? { users, ...action.payload } : users
        ),
        error: {},
        addUpdateSuccess: true,
      }

    case UPDATE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        users: state.users.filter(users => users.id !== action.usersId),
        deleteSuccess: true,
        error: {},
      }

    case DELETE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case VERIFY_USERS_SUCCESS:
      return {
        ...state,
        users: state.users.map(users =>
          users.id === action.payload.id ? { users, ...action.payload } : users
        ),
        addUpdateSuccess: true,
        error: {},
      }

    case VERIFY_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case REJECT_USERS_SUCCESS:
      return {
        ...state,
        users: state.users.map(users =>
          users.id === action.payload.id ? { users, ...action.payload } : users
        ),
        addUpdateSuccess: true,
        error: {},
      }

    case REJECT_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateSuccess: false,
      }

    case GET_DELETED_USERS_SUCCESS:
      return {
        ...state,
        deletedUsers: action.payload,
        deleteSuccess: null,
        error: {},
      }

    case GET_DELETED_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: null,
      }

    case DELETE_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        deletedUsers: state.deletedUsers.filter(
          user => user.id !== action.usersId
        ),
        deleteSuccess: true,
        error: {},
      }

    case DELETE_USERS_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        deleteSuccess: false,
      }

    case UPDATE_DELETE_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.payload,
      }

    case FREEZE_USERS_SUCCESS:
      return {
        ...state,
        freezeSuccess: true,
      }

    case FREEZE_USERS_FAIL:
      return {
        ...state,
        freezeSuccess: false,
      }

    default:
      return state
  }
}

export default Users
