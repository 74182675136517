import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import {
  getDeletedUsers as onGetDeletedUsers,
  deleteUserRequest as onDeleteUserRequest,
  updateDeleteSuccessFlag as updateUserDeleteSuccessFlag,
} from "store/users/actions"
import { toast } from "react-toastify"
import axios from "axios"
import { getAccessToken } from "helpers/jwt-token-access/accessToken"

const UserDeletion = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetDeletedUsers())
  }, [])

  const { deletedUsers, error, userDeleteSuccess } = useSelector(state => ({
    deletedUsers: state.Users.deletedUsers,
    error: state.Users.error,
    deleteSuccess: state.Users.deleteSuccess,
  }))

  const [usersList, setUsersList] = useState([])
  const [selectedUser, setSelectedUser] = useState([])
  const [freezeUserList, setFreezeUserList] = useState([])

  useEffect(() => {
    if (error?.status === 401) {
      history.push("/login")
    } else if (userDeleteSuccess) {
      toast.success(`User deleted successfully`, { autoClose: 2000 })
    } else if (
      !userDeleteSuccess &&
      userDeleteSuccess !== null &&
      userDeleteSuccess !== undefined &&
      userDeleteSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateUserDeleteSuccessFlag(null))
  }, [error, userDeleteSuccess])

  useEffect(() => {
    axios
      .get(
        "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user?status=freeze",
        { headers: { Authorization: getAccessToken() } }
      )
      .then(res => {
        console.log(res.data, "Freeze Data")
        setFreezeUserList(res.data.data)
      })
  }, [])

  //delete User
  const [deleteModalUser, setDeleteModalUser] = useState(false)

  const handleDeleteRecord = () => {
    handleDeleteUser()
  }

  const onClickDeleteUser = selectedUser => {
    setSelectedUser(selectedUser)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (selectedUser.id) {
      dispatch(onDeleteUserRequest(selectedUser.id))
      setDeleteModal(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeUsers = (type, { page, searchText }) => {
    setUsersList(
      user.filter(user =>
        Object.keys(user).some(key =>
          user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  const usersPageOptions = {
    sizePerPage: 5,
    totalSize: usersList.length, // replace later with size(orders),
    custom: true,
  }

  const freezeUsersPageOptions = {
    sizePerPage: 5,
    totalSize: freezeUserList.length, // replace later with size(orders),
    custom: true,
  }

  const usersColumns = () => [
    {
      dataField: "id",
      text: "User ID",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Id",
      sort: true,
    },
    {
      dataField: "role",
      text: "User Type",
      sort: true,
    },
    {
      dataField: "deletereason",
      text: "Delete Reason",
      sort: true,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => onClickDeleteUser(user)}
            >
              <i
                className="mdi mdi-check-decagram font-size-18"
                id="approvetooltip"
              />
              <UncontrolledTooltip placement="top" target="approvetooltip">
                Approve
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const freezeUsersColumns = () => [
    {
      dataField: "id",
      text: "User ID",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Id",
      sort: true,
    },
    {
      dataField: "role",
      text: "User Type",
      sort: true,
    },
    // {
    //   dataField: "deletereason",
    //   text: "Delete Reason",
    //   sort: true,
    // },
    // {
    //   dataField: "action",
    //   isDummyField: true,
    //   text: "Action",
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, user) => (
    //     <>
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-success"
    //           onClick={() => onClickDeleteUser(user)}
    //         >
    //           <i
    //             className="mdi mdi-check-decagram font-size-18"
    //             id="approvetooltip"
    //           />
    //           <UncontrolledTooltip placement="top" target="approvetooltip">
    //             Approve
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     </>
    //   ),
    // },
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const defaultSorted = [
    {
      dataField: "postTitle",
      order: "desc",
    },
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRecord}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>User Delete Request and Freezed Users</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="User Delete Request and Freezed Users"
            breadcrumbItem="User Delete Request and Freezed Users"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Row className="mb-2">
                        <Col sm="6">
                          <CardTitle className="mb-4">Delete Request</CardTitle>
                        </Col>
                      </Row>

                      <PaginationProvider
                        pagination={paginationFactory(usersPageOptions)}
                        keyField="id"
                        columns={usersColumns()}
                        data={deletedUsers}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={deletedUsers}
                            columns={usersColumns()}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        ref={node}
                                        onClick={handleTableChangeUsers}
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Data Available
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Row className="mb-2">
                        <Col sm="6">
                          <CardTitle className="mb-4">Freezed Users</CardTitle>
                        </Col>
                      </Row>

                      <PaginationProvider
                        pagination={paginationFactory(freezeUsersPageOptions)}
                        keyField="id"
                        columns={freezeUsersColumns()}
                        data={freezeUserList}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={freezeUserList}
                            columns={freezeUsersColumns()}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        ref={node}
                                        onClick={handleTableChangeUsers}
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Data Available
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserDeletion
