import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import {
  getUserRole as onGetUserRole,
  deleteUserRole as onDeleteUserRole,
  updateDeleteSuccessFlag as updateUserDeleteSuccessFlag,
} from "store/userRole/actions"
import { freezeUser } from "store/users/actions"
import { toast } from "react-toastify"

const UserRole = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [refresh, setRefresh] = useState(false) // state variable to trigger refresh

  useEffect(() => {
    dispatch(onGetUserRole())
  }, [])

  const { userRole, error, userRoleSuccess, freezeSuccess } = useSelector(
    state => ({
      userRole: state.UserRole.userroleList,
      error: state.UserRole.error,
      userRoleSuccess: state.UserRole.deleteSuccess,
      freezeSuccess: state.Users.freezeSuccess,
    })
  )

  const [usersList, setUsersList] = useState([])
  const [selectedUser, setSelectedUser] = useState([])

  useEffect(() => {
    if (error?.status === 401) {
      history.push("/login")
    } else if (userRoleSuccess) {
      toast.success(`User deleted successfully`, { autoClose: 2000 })
    } else if (
      !userRoleSuccess &&
      userRoleSuccess !== null &&
      userRoleSuccess !== undefined &&
      userRoleSuccess !== ""
    ) {
      toast.error(`Error! Something went wrong.`, { autoClose: 2000 })
    }
    dispatch(updateUserDeleteSuccessFlag(null))
  }, [error, userRoleSuccess])

  //delete User
  const [deleteModalUser, setDeleteModalUser] = useState(false)

  const handleDeleteRecord = () => {
    handleDeleteUser()
  }

  const onClickDeleteUser = selectedUser => {
    setSelectedUser(selectedUser)
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (selectedUser.id) {
      dispatch(onDeleteUserRole(selectedUser.id))
      setDeleteModal(false)
      //setRefresh(!refresh); // Trigger refresh
    }
  }
  const toggleDocumentStatus = (id, status) => {
    const obj = {
      id: id,
      isActive: status,
    }
    dispatch(freezeUser(obj))
    //setRefresh(!refresh); // Trigger refresh
  }

  useEffect(() => {
    if (freezeSuccess === true) {
      toast.success("Account has been Approved!")
      dispatch(onGetUserRole())
    } else if (freezeSuccess === false) {
      toast.error("Something went wrong!")
    }
  }, [freezeSuccess])

  // eslint-disable-next-line no-unused-vars
  const handleTableChangeUsers = (type, { page, searchText }) => {
    setUsersList(
      user.filter(user =>
        Object.keys(user).some(key =>
          user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  const usersPageOptions = {
    sizePerPage: 5,
    totalSize: usersList.length, // replace later with size(orders),
    custom: true,
  }

  const usersColumns = () => [
    {
      dataField: "id",
      text: "User ID",
      sort: true,
    },
    {
      dataField: "contact",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Id",
      sort: true,
    },
    {
      dataField: "role",
      text: "User Type",
      sort: true,
    },
    /* {
      dataField: "deletereason",
      text: "Delete Reason",
      sort: true,
    }, */
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, userRole) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={`/add-userRole?id=${userRole.id}`}
              className="text-success"
              //onClick={() => onClickDelete(order)}
            >
              <i className="bx bxs-edit-alt font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDeleteUser(userRole)}
            >
              <i className="bx bxs-trash font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>

            {userRole?.isActive !== false ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => toggleDocumentStatus(userRole.id, false)}
              >
                <i
                  className="mdi mdi-alpha-x-circle font-size-18"
                  id="approvetooltip"
                />
                <UncontrolledTooltip placement="top" target="approvetooltip">
                  Freeze
                </UncontrolledTooltip>
              </Link>
            ) : (
              <Link
                to="#"
                className="text-success"
                onClick={() => toggleDocumentStatus(userRole.id, true)}
              >
                <i
                  className="mdi mdi-check-decagram font-size-18"
                  id="rejecttooltip"
                />
                <UncontrolledTooltip placement="top" target="rejecttooltip">
                  Approve
                </UncontrolledTooltip>
              </Link>
            )}
          </div>
        </>
      ),
    },
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const defaultSorted = [
    {
      dataField: "postTitle",
      order: "desc",
    },
  ]

  //const userroleList = userRole.filter(item => (item.role).toLowerCase() === "admin");

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteRecord}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>User Role</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="User Role" breadcrumbItem="User Role" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <Row className="mb-2">
                        <Col sm="6">
                          <CardTitle className="mb-4">User Role</CardTitle>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        {/* <Col sm="6">
                            <CardTitle className="mb-4">Companies</CardTitle>
                          </Col> */}
                        <Col sm="12">
                          <div className="text-sm-end">
                            <Link
                              type="button"
                              color="success"
                              className="btn-rounded mb-2 me-2 btn btn-primary"
                              //onClick={handleOrderClicks}
                              to="/add-userRole"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Add User Role
                            </Link>
                          </div>
                        </Col>
                      </Row>

                      <PaginationProvider
                        pagination={paginationFactory(usersPageOptions)}
                        keyField="id"
                        columns={usersColumns()}
                        data={userRole}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={userRole}
                            columns={usersColumns()}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField="id"
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        ref={node}
                                        onClick={handleTableChangeUsers}
                                        noDataIndication={() => (
                                          <div className="text-center">
                                            No Data Available
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserRole
