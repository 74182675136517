import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import { submit } from "redux-form"
import { events } from "common/data"
import {
  verifyUsers as onVerifyUsers,
  rejectUsers as onRejectUsers
} from "store/users/actions"
import { useDispatch } from "react-redux"
const UsersModal = props => {
  const dispatch = useDispatch();
  const { isOpen, toggle, imageUrl, rejectType } = props
  const [reason, setReason] = useState('')
  const submitReason = () => {
    console.log("submit reason")
    imageUrl.rejectionreason = reason;
    if(rejectType === 'document')
      dispatch(onRejectUsers(imageUrl))
    else
      dispatch(onVerifyUsers(imageUrl))
  }
  const handleOnBlur = (event) =>{
    //console.log(e)
    //setReason();
    const value = event.target.value;
    
   }
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      //imageUrl = {imageUrl}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Reject Reason</ModalHeader>
        <ModalBody>
          <div>
            <textarea placeholder="Please Provide Reason for Reject" 
            onBlur={handleOnBlur} 
            onChange={(event) => setReason(event.target.value)}
            className="form-control"
            required
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={submitReason} className="btn btn-success" disabled={reason===""}>
            Submit
          </Button>
          <Button type="button" color="secondary" onClick={toggle} className="btn btn-success">
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

UsersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  imageUrl: PropTypes.string,
  rejectType: PropTypes.string
}

export default UsersModal
