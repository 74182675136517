import axios from "axios"
import { del, get, post, put, postDocument, patch } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)
//.then(response => {
//   console.log(response)
//   if (response.status >= 200 || response.status <= 299) return response.data
//   throw response.data
// }).catch(err => {
//   //console.log("Error==>"+err)
//   var message
//   if (err.response && err.response.status) {
//     switch (err.response.status) {
//       case 404:
//         message = "Sorry! the page you are looking for could not be found"
//         break
//       case 500:
//         message =
//           "Sorry! something went wrong, please contact our support team"
//         break
//       case 401:
//         message = "Invalid credentials"
//         break
//       default:
//         message = err[1]
//         break
//     }
//   }
//   throw message
// })

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
//export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

//company

// get company
export const getCompany = () => get(`${url.GET_COMPANY}`)
export const getCompanyById = companyId =>
  get(
    `${url.GET_COMPANY}${
      companyId !== undefined
        ? "?id=" + companyId.companyId + "&lang_id=" + companyId.lang_id
        : ""
    }`
  )
export const addNewCompany = company => post(url.ADD_NEW_COMPANY, company)
export const updateCompany = company => put(url.UPDATE_COMPANY, company)
export const deleteCompany = company =>
  del(`${url.DELETE_COMPANY}?id=${company}`)
export const deleteSectionEntry = (entryId, entryType) =>
  del(`${url.DELETE_SECTION_ENTRY}?id=${entryId}&model=${entryType}`)
// Get Language
export const getLanguage = () => get(url.GET_LANGUAGE)

// Get Sustain Goal Array
export const getSustainGoal = () => get(url.GET_SUSTAIN_GOAL)

// HighLights
export const getHighlights = () => get(url.GET_HIGHLIGHTS)
export const getHighlightsById = highlightsId =>
  get(
    `${url.GET_HIGHLIGHTS}${
      highlightsId !== undefined ? "?id=" + highlightsId : ""
    }`
  )
export const addNewHighlights = highlights =>
  post(url.ADD_NEW_HIGHLIGHTS, highlights)
export const updateHighlights = highlights =>
  put(url.UPDATE_HIGHLIGHTS, highlights)
export const deleteHighlights = highlights =>
  del(`${url.DELETE_HIGHLIGHTS}?id=${highlights}`)

// News
export const getNews = () => get(url.GET_NEWS)
export const getNewsById = newsId =>
  get(
    `${url.GET_NEWS}${
      newsId !== undefined
        ? "?newcode=" + newsId.newcode + "&lang_id=" + newsId.lang_id
        : ""
    }`
  )
export const addNewNews = news => post(url.ADD_NEW_NEWS, news)
export const updateNews = news => put(url.UPDATE_NEWS, news)
export const deleteNews = news => del(`${url.DELETE_NEWS}?id=${news}`)

// Insights
export const getInsights = () => get(url.GET_INSIGHTS)
export const getInsightsById = insightsId =>
  get(
    `${url.GET_INSIGHTS}${insightsId !== undefined ? "?id=" + insightsId : ""}`
  )
export const addNewInsights = insights => post(url.ADD_NEW_INSIGHTS, insights)
export const updateInsights = insights => put(url.UPDATE_INSIGHTS, insights)
export const deleteInsights = insights =>
  del(`${url.DELETE_INSIGHTS}?id=${insights}`)

// Reports
export const getReports = () => get(url.GET_REPORTS)
export const getReportsById = reportsId =>
  get(`${url.GET_REPORTS}${reportsId !== undefined ? "?id=" + reportsId : ""}`)
export const addNewReports = reports => post(url.ADD_NEW_REPORTS, reports)
export const updateReports = reports => put(url.UPDATE_REPORTS, reports)
export const deleteReports = reports =>
  del(`${url.DELETE_REPORTS}?id=${reports}`)

// Users
export const getUsers = () => get(url.GET_USERS)
export const getUsersById = usersId =>
  get(`${url.GET_USERS}${usersId !== undefined ? "?id=" + usersId : ""}`)
export const addNewUsers = users => post(url.ADD_NEW_USERS, users)
export const updateUsers = users => put(url.UPDATE_USERS, users)
export const deleteUsers = users => del(`${url.DELETE_USERS}?id=${users}`)
export const verifyUsers = users => patch(url.VERIFY_USERS, users)
export const rejectUsers = users => patch(url.REJECT_USERS, users)
export const getDeletedUsers = () => get(url.GET_DELETED_USERS)
export const deleteUserRequest = users =>
  del(`${url.DELETE_USER_REQUEST}?id=${users}`)

export const postUploadDocument = async document => {
  console.log("postUploadDocument", document)
  const reqData = new FormData()
  reqData.append("file", document)
  reqData.append("type", document.type.includes("pdf") ? "document" : "images")
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }
  try {
    const response = await postDocument(url.UPLOAD_DOCUMENT, reqData, options)
    return response
  } catch (error) {
    console.error("postUploadDocument error:", error)
    throw error
  }
}

// Get Country Array
export const getCountry = () => get(url.GET_COUNTRY)

//Get interstnfavourite
export const getInterestNFavourite = () => get(url.GET_INTERESTNFAVOURITE)
export const getInterestNFavouriteById = usersId =>
  get(
    `${url.GET_INTERESTNFAVOURITE}${
      usersId !== undefined ? "?highlightid=" + usersId : ""
    }`
  )

//Get interstnfavourite
export const getTransaction = () => get(url.GET_TRANSACTION)
export const getTransactionById = usersId =>
  get(`${url.GET_TRANSACTION}${usersId !== undefined ? "?id=" + usersId : ""}`)
export const updateTransaction = transaction =>
  put(url.UPDATE_TRANSACTION, transaction)
export const getActiveChatRequestHistoryById = activerequestid =>
  get(
    `${url.GET_ACTIVECHATREQUESTHISTORY}${
      activerequestid !== undefined
        ? "?activechatrequestid=" + activerequestid
        : ""
    }`
  )

export const addNewPortfolio = payload => post(url.PORTFOLIO, payload)
export const updatePortfolio = payload => put(url.PORTFOLIO, payload)
export const deletePortfolio = portfolioId =>
  del(`${url.PORTFOLIO}?id=${portfolioId}`)
export const getComplaints = () => get(url.GET_COMPLAINTS)
export const getComplaintsById = usersId =>
  get(`${url.GET_COMPLAINTS}${usersId !== undefined ? "?id=" + usersId : ""}`)
export const updateComplaints = Complaints =>
  put(url.GET_COMPLAINTS, Complaints)
// Freeze user

export const freezeUser = obj => patch(url.FREEZE_USER, obj)

export const getUserRole = () =>
  get(`${url.GET_USERS}${"?role=admin&role=editor&role=ai_officer"}`)
export const getUserRoleById = usersId =>
  get(
    `${url.GET_USERS}${
      usersId !== undefined
        ? "?role=admin&role=editor&role=ai_officer&id=" + usersId
        : ""
    }`
  )
export const addNewUserRole = users => post(url.ADD_NEW_USERS, users)
export const updateUserRole = users => put(url.UPDATE_USERS, users)
//export const deleteUserRole = users => del(`${url.DELETE_USERROLE}?id=${users}`)
export const deleteUserRole = users => del(`${url.DELETE_USERS}?id=${users}`)

export const getNotifications = () => get(url.GET_NOTIFICATIONS)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
