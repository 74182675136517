import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Editor } from "react-draft-wysiwyg"
import { convertToRaw, ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  /* addNewTransaction as onAddNewTransaction,
  updateTransaction as onUpdateTransaction, */
  getTransactionById as onGetTransactionById,
  getActiveChatRequestHistoryById,
  updateTransaction,
} from "store/transaction/actions"
import { updatePortfolio as onUpdatePortfolio } from "store/portfolio/actions"
import { uploadDocument as uploadDocument } from "store/document-upload/actions"
import { toast } from "react-toastify"
import DisplayModal from "./DisplayModal"
const initialValues = {
  id: null,
  transactionDate: null,
  buyer: 1,
  seller: 2,
  company: 1,
  price: "",
  quantity: "",
  message: "",
}

const validationSchema = Yup.object({
  price: Yup.string().required("Please Enter Price"),
  quantity: Yup.string().required("Please Enter Quantity"),
  message: Yup.string().required("Please enter message"),
})

const TransactionDetails = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const transactionId = searchParams.get("id")
  const language = JSON.parse(localStorage.getItem("language"))
  const userId = JSON.parse(localStorage.getItem("authUser"))
  const [isEdit, setIsEdit] = useState(true)
  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [selectedFiles, setSelectedFiles] = useState([])
  const [basicUserFlag, setBasicUserFlag] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)
  const [activeRequestId, setActiveRequestId] = useState("")
  const [activeChatRequestHistoryData, setActiveChatRequestHistoryData] =
    useState([])
  let chatData = []

  const { transactionById, error, activeChatRequestHistoryById } = useSelector(
    state => ({
      transactionById: state.Transaction.transactionById,
      activeChatRequestHistoryById:
        state.Transaction.activeChatRequestHistoryById,
      error: state.Transaction.error,
    })
  )

  useEffect(() => {
    console.log(userId)
    if (transactionId !== null && transactionId !== undefined) {
      //call api to get company data;
      dispatch(onGetTransactionById(transactionId))
    }
  }, [transactionId])

  useEffect(() => {
    console.log("Transaction", transactionById)
    if (transactionById.length > 0 && transactionId !== null) {
      setSelectedTransaction(transactionById[0])
      setIsEdit(true)
      assignInitialValues(transactionById[0])
      setActiveRequestId(transactionById[0]?.activechatrequestid)
    }
  }, [transactionById])

  useEffect(() => {
    if (transactionById.length !== 0 && activeRequestId !== null) {
      dispatch(
        getActiveChatRequestHistoryById(transactionById[0]?.activechatrequestid)
      )
    }
  }, [transactionById])

  useEffect(() => {
    setActiveChatRequestHistoryData(activeChatRequestHistoryById)
  }, [activeChatRequestHistoryById])

  const assignInitialValues = transaction => {
    console.log('transaction', transaction);
    validation.setFieldValue("price", transaction?.price)
    validation.setFieldValue(
      "buyer",
      transaction?.Buyer_Detail?.role === 'legalrepresent' ? transaction?.Buyer_Detail?.lrDetails[0]?.companyname : transaction?.Buyer_Detail?.userdetail.firstname
    )
    validation.setFieldValue(
      "seller",
      transaction?.Seller_Detail?.role === 'legalrepresent' ? transaction?.Seller_Detail?.lrDetails[0]?.companyname : transaction?.Seller_Detail?.userdetail.firstname
    )
    validation.setFieldValue("company", transaction?.Company?.name)
    validation.setFieldValue(
      "transactionDate",
      transaction?.transact_date.split(" ")[0]
    )
    validation.setFieldValue("quantity", transaction?.quantity)
    validation.setFieldValue("message", transaction?.message)
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: values => {
      let payload = {
        userid: userId?.id,
        // transact_date: values.transactionDate,
        price: values.price,
        quantity: values.quantity,
        message: values.message,
        id: transactionId,
        buyer_id: transactionById[0].buyer_id,
        seller_id: transactionById[0].seller_id,
        company_id: transactionById[0].company_id,
        status: "completed",
        activechatrequestid: transactionById[0].activechatrequestid,
      }
      dispatch(updateTransaction(payload))
      setDisplayModal(true)
      // if (isEdit) {
      //   console.log("updatePortfolioPayload", payload)
      //   // update company
      //   dispatch(onUpdatePortfolio(payload))
      //   setDisplayModal(true)
      //   // history.push('/templates');
      // } else {
      //   console.log("newtransactionPayload", payload)
      //   // save new customer
      //   dispatch(onAddNewTransaction(payload))
      //   validation.resetForm()
      //   // history.push('/templates');
      // }
    },
  })

  const handleDisplay = () => {
    /* if (selectedCompany.id) {
      dispatch(onDeleteCompany(selectedCompany.id)); */
    setDisplayModal(true)
    // }
  }

  const downloadChat = () => {
    // document.getElementById('download-btn').addEventListener('click', function() {
    // Get all chat items
    // Get all chat items and convert the NodeList to an array
    const chatItems = Array.from(document.querySelectorAll(".chat-item"))

    let chatContent = ""

    // Loop through each chat item to extract the content
    chatItems.forEach(item => {
      const name = item.querySelector(".name").textContent
      const msg = item.querySelector(".chat-msg").textContent
      const time = item.querySelector(".time").textContent
      chatContent += `${name} [${time}]: ${msg}\n`
    })

    console.log(chatContent)

    // Create a blob with the chat content
    const blob = new Blob([chatContent], { type: "text/plain" })

    // Create a link element
    const a = document.createElement("a")
    a.href = URL.createObjectURL(blob)
    a.download = `TransactionChat_${transactionId}.txt`

    // Append the link to the body (required for Firefox)
    document.body.appendChild(a)

    // Programmatically click the link to trigger the download
    a.click()

    // Remove the link from the document
    document.body.removeChild(a)
    // });
  }

  return (
    <React.Fragment>
      <DisplayModal
        show={displayModal}
        onClick={handleDisplay}
        onCloseClick={() => setDisplayModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Transaction Details</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Transaction" breadcrumbItem="Edit Transaction" />
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-12">Date</Label>
                      <Col lg="10">
                        <Row>
                          <Col className="pl-0">
                            <Input
                              className="form-control"
                              type="date"
                              defaultValue="2019-08-19"
                              id="transactionDate"
                              name="transactionDate"
                              onChange={event => {
                                validation.setFieldValue(
                                  "transactionDate",
                                  new Date(event.target.value)
                                )
                              }}
                              selected={validation.values.transactionDate}
                              value={validation.values.transactionDate}
                              readOnly
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="headline"
                        className="col-form-label col-lg-12"
                      >
                        Buyer
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="buyer"
                          type="text"
                          className="form-control"
                          placeholder="Enter title..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.buyer}
                          readOnly
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="headline"
                        className="col-form-label col-lg-12"
                      >
                        Seller
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="seller"
                          type="text"
                          className="form-control"
                          placeholder="Enter title..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seller}
                          readOnly
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="headline"
                        className="col-form-label col-lg-12"
                      >
                        Company Name
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="company"
                          type="text"
                          className="form-control"
                          placeholder="Enter title..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.company}
                          readOnly
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="headline"
                        className="col-form-label col-lg-12"
                      >
                        Price
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.price && validation.errors.price
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="title"
                          name="price"
                          type="text"
                          className="form-control"
                          placeholder="Enter price..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.price}
                          readOnly={transactionById[0]?.status === "completed"}
                          invalid={
                            validation.touched.price && validation.errors.price
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.price && validation.errors.price ? (
                        <FormFeedback type="invalid">
                          {validation.errors.price}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="headline"
                        className="col-form-label col-lg-12"
                      >
                        Quantity
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.quantity &&
                          validation.errors.quantity
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="title"
                          name="quantity"
                          type="text"
                          className="form-control"
                          placeholder="Enter Quantity..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.quantity}
                          readOnly={transactionById[0]?.status === "completed"}
                          invalid={
                            validation.touched.quantity &&
                            validation.errors.quantity
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.quantity &&
                      validation.errors.quantity ? (
                        <FormFeedback type="invalid">
                          {validation.errors.quantity}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="headline"
                        className="col-form-label col-lg-12"
                      >
                        Message
                      </Label>
                      <Col
                        lg="10"
                        className={`${
                          validation.touched.message &&
                          validation.errors.message
                            ? "is-invalid"
                            : ""
                        }`}
                      >
                        <Input
                          id="title"
                          name="message"
                          type="textarea"
                          className="form-control"
                          placeholder="Enter Message"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.message}
                          readOnly={transactionById[0]?.status === "completed"}
                          invalid={
                            validation.touched.message &&
                            validation.errors.message
                              ? true
                              : false
                          }
                        />
                      </Col>
                      {validation.touched.message &&
                      validation.errors.message ? (
                        <FormFeedback type="invalid">
                          {validation.errors.message}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="bs-chat-section">
                  <div className="chat-head">
                    <h3 className="section-title">Transaction Chat</h3>
                    <button type="button" onClick={downloadChat}>
                      Download Chat
                    </button>
                  </div>
                  <div className="chat-section">
                    <ul className="chat-list">
                      {activeChatRequestHistoryData
                        .slice()
                        .reverse()
                        .map((item, index) => {
                          return (
                            <li
                              key={index}
                              className={`chat-item ${
                                item.sender_id === transactionById[0].buyer_id
                                  ? "buyer"
                                  : ""
                              }`}
                            >
                              <div className="chat-card">
                                <strong className="name">
                                  {item.Sender?.role === 'legalrepresent' ? item.Sender?.lrDetails[0]?.companyname : item.Sender?.userdetail.firstname}
                                </strong>
                                <div className="chat-msg">{item.message}</div>
                                <span className="time">
                                  {item.lastUpdateDate}
                                </span>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                  <Button
                    color="primary"
                    type="submit"
                    className="btn-rounded p-4 pb-2 pt-2"
                    disabled={transactionById[0]?.status === "completed"}
                  >
                    Approve
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TransactionDetails
