import React, { useEffect, useRef } from "react"

function EmbeddedApp() {
  // const iframeRef = useRef(null)
  // const iframeObj = document.getElementById('onboardingIframe');
  // iframeObj.contentWindow.postMessage('getUrl', '*');

  // useEffect(() => {
  //   const handleMessage = event => {
  //     if (!event.origin.includes("https://cmsuat.application-ai-x.com")) {
  //     // if (event.origin !== "http://localhost:3000") {
  //       return // Ensure that the origin is the expected one
  //     }
  //     if (event.data.type === "getUrl") {
  //       const parentUrl = document.referrer; // Get the parent URL
  //       event.source.postMessage(parentUrl, event.origin);

  //       // const localStorageData = localStorage.getItem(event.data.key)
  //       // iframeRef.current.contentWindow.postMessage(
  //       //   {
  //       //     type: "localStorageData",
  //       //     key: event.data.key,
  //       //     data: localStorageData,
  //       //   },
  //       //   "https://feuat.application-ai-x.com"
  //       //   // "http://localhost:3000/login"
  //       // )
  //     }
  //   }

  //   window.addEventListener("message", handleMessage)

  //   return () => {
  //     window.removeEventListener("message", handleMessage)
  //   }
  // }, [])

  // const sendMessageToChild = () => {
  //   iframeRef.current.contentWindow.postMessage(
  //     { type: "init" },
  //     // "http://localhost:3001/login"
  //     "https://cmsuat.application-ai-x.com/login"
  //   )
  // }
  const parentUrl = window.location.href;

  return (
    <iframe
      src={`https://feuat.application-ai-x.com/login?parentUrl=${encodeURIComponent(parentUrl)}`}
      // src={`http://localhost:3000/login?parentUrl=${encodeURIComponent(parentUrl)}`}
      style={{ width: "100%", height: "100vh", border: "none" }}
      title="Embedded Application"
      // ref={iframeRef}
      id="onboardingIframe"
      // onLoad={sendMessageToChild}
    />
  )
}

export default EmbeddedApp
