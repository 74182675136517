import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { textFilter } from 'react-bootstrap-table2-filter';
import * as Yup from "yup"
import { useFormik } from "formik"
import "./Users.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import classnames from "classnames"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Accordion,
  AccordionHeader,
  AccordionItem,
  CardText,
  CardTitle,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getUsers as onGetUsers,
  addNewUsers as onAddNewUsers,
  updateUsers as onUpdateUsers,
  deleteUsers as onDeleteUsers,
} from "store/users/actions"

import UserModal from "./UserModal"
import DeleteModal from "components/Common/DeleteModal"

const Users = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { users, error } = useSelector(state => ({
    users: state.Users.users,
    error: state.Users.error,
  }))

  const [usersList, setUsersList] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [investors, setInvestors] = useState([])
  const [advisors, setAdvisors] = useState([])
  const [company, setCompany] = useState([])

  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const companyUsers = users.filter(
    user => user.role === "legalrepresent" && user.status === "approved"
  )
  const investorsUsers = users.filter(
    user => user.role === "individual_investor" && user.status === "approved"
  )
  const advisorsUsers = users.filter(
    user =>
      (user.role === "advisor" || user.role === "basic") &&
      user.status === "approved"
  )
  const newUsers = users.filter(
    user => user.status === "document approval pending"
  )

  const allUsers = users.filter(
    user => user.status === "approved" || user.status === "freeze"
  )

  useEffect(() => {
    dispatch(onGetUsers())
  }, [])

  useEffect(() => {
    if (error?.status === 401) history.push("/login")
  }, [error])

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const selectRow = {
    mode: "checkbox",
  }

  //pagination customization
  const newUsersPageOptions = {
    sizePerPage: 5,
    totalSize: newUsers.length, // replace later with size(orders),
    custom: true,
  }

  const allUsersPageOptions = {
    sizePerPage: 5,
    totalSize: allUsers.length, // replace later with size(orders),
    custom: true,
  }

  const investorUsersPageOptions = {
    sizePerPage: 5,
    totalSize: investorsUsers.length, // replace later with size(orders),
    custom: true,
  }

  const advisorUsersPageOptions = {
    sizePerPage: 5,
    totalSize: advisorsUsers.length, // replace later with size(orders),
    custom: true,
  }

  const companyUsersPageOptions = {
    sizePerPage: 5,
    totalSize: companyUsers.length, // replace later with size(orders),
    custom: true,
  }

  const toggleViewModal = () => setModal1(!modal1)

  const toLowerCase1 = str => {
    return str === "" || str === undefined ? "" : str.toLowerCase()
  }

  const usersColumns = () => [
    {
      dataField: "id",
      text: "User ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      /* formatter: (cellContent, row) => (
        <Link to={row.type === 'Investor' ? `/users-details/${row.id}` : row.type === 'Advisor' ? `/advisor-details/${row.id}` : `/legal-entity-details/${row.id}`} className="text-body fw-bold">
          {row.id}
        </Link>
      ), */
    },
    {
      dataField: "name",
      isDummyField: true,
      text: "Name",
      sort: true,
      formatter: (cellContent, row) => {
        const formatter = (cellContent, row) => (
          <span>
            {row.role === "legalrepresent"
              ? row?.userdetail?.userlrdetail === undefined ||
                row?.userdetail?.userlrdetail.length === 0
                ? "-"
                : row?.userdetail?.userlrdetail[0]?.companyname
              : row?.userdetail?.firstname === undefined ||
                row?.userdetail?.firstname === null
              ? "-"
              : `${row?.userdetail?.firstname} ${row?.userdetail?.lastname}`}
          </span>
        )
        formatter.displayName = "NameFormatter"
        return formatter(cellContent, row)
      }
    },
    // {
    //   dataField: "firstname",
    //   text: "First Name",
    //   sort: true,
    // },
    // {
    //   dataField: "lastname",
    //   text: "Last Name",
    //   sort: true,
    // },
    {
      dataField: "contact",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Id",
      sort: true,
    },
    {
      dataField: "role",
      text: "User Type",
      sort: true,
    },
    // {
    //   dataField: "view",
    //   isDummyField: true,
    //   text: "View Documents",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: () => (
    //     <Button
    //       type="button"
    //       color="primary"
    //       className="btn-sm btn-rounded"
    //       onClick={toggleViewModal}
    //     >
    //       View
    //     </Button>
    //   ),
    // },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={
                row.role === "individual_investor"
                  ? `/users-details?id=${row.id}`
                  : row.role === "advisor"
                  ? `/advisor-details?id=${row.id}`
                  : row.role === "basic"
                  ? `/advisor-details?id=${row.id}`
                  : row.role === "legalrepresent"
                  ? `/legal-entity-details?id=${row.id}`
                  : `/users-details?id=${row.id}`
              }
              className="btn-sm btn-rounded"
              color="primary"
            >
              <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(row)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Remove
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const newUsersColumns = () => [
    {
      dataField: "id",
      text: "User ID",
      sort: true,
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, row) => (
      // <Link to={row.type === 'Investor' ? `/users-details/${row.id}` : row.type === 'Advisor' ? '' : ''} className="text-body fw-bold">
      // {row.id}
      // </Link>
      // ),
      // eslint-disable-next-line react/display-name
      /* formatter: (_cellContent, row) => (
        <Link to={row.type === 'Investor' ? `/users-details/${row.id}` : row.type === 'Advisor' ? `/advisor-details/${row.id}` : `/legal-entity-details/${row.id}`} className="text-body fw-bold">
          {row.id}
        </Link>
      ), */
    },
    {
      dataField: "name",
      isDummyField: true,
      text: "Name",
      sort: true,
      // filter: textFilter(),
      formatter: (cellContent, row) => {
        const formatter = (cellContent, row) => (
          <span>
            {row.role === "legalrepresent"
              ? row?.userdetail?.userlrdetail === undefined ||
                row?.userdetail?.userlrdetail.length === 0
                ? "-"
                : row?.userdetail?.userlrdetail[0]?.companyname
              : row?.userdetail?.firstname === undefined ||
                row?.userdetail?.firstname === null
              ? "-"
              : `${row?.userdetail?.firstname} ${row?.userdetail?.lastname}`}
            {/* {(row.userdetail.firstname === undefined || row.userdetail.firstname === null) ? '-' : row.role === 'legalrepresent' ? `${row.userdetail.firstname}` : `${row.userdetail.firstname} ${row.userdetail.lastname}`} */}
          </span>
        )
        formatter.displayName = "NameFormatter"
        return formatter(cellContent, row)
      },
    },
    // {
    //   dataField: "firstname",
    //   text: "First Name",
    //   sort: true,
    // },
    // {
    //   dataField: "lastname",
    //   text: "Last Name",
    //   sort: true,
    // },
    {
      dataField: "contact",
      text: "Mobile No",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Id",
      sort: true,
    },
    {
      dataField: "role",
      text: "User Type",
      sort: true,
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: true,
      // eslint-disable-next-line react/display-name
      /* formatter: () => (
        <Button
          type="button"
          color="primary"
          className="btn-sm btn-rounded"
          onClick={toggleViewModal}
        >
          View
        </Button>
      ), */
      // eslint-disable-next-line react/display-name
      formatter: (_cellContent, row) => (
        <Link
          to={
            row.role === "individual_investor"
              ? `/users-details?id=${row.id}`
              : row.role === "advisor"
              ? `/advisor-details?id=${row.id}`
              : row.role === "basic"
              ? `/advisor-details?id=${row.id}`
              : row.role === "legalrepresent"
              ? `/legal-entity-details?id=${row.id}`
              : `/users-details?id=${row.id}`
          }
          className="btn-sm btn-rounded"
          color="primary"
        >
          <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
          <UncontrolledTooltip placement="top" target="viewtooltip">
            View
          </UncontrolledTooltip>
        </Link>
      ),
    },
    // {
    //   dataField: "action",
    //   isDummyField: true,
    //   text: "Action",
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, order) => (
    //     <>
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-success"
    //           //onClick={() => handleOrderClick(order)}
    //         >
    //           <i className="mdi mdi-check-bold font-size-18" id="edittooltip" />
    //           <UncontrolledTooltip placement="top" target="edittooltip">
    //             Approve
    //           </UncontrolledTooltip>
    //         </Link>
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           //onClick={() => onClickDelete(order)}
    //         >
    //           <i className="mdi mdi-alpha-x-circle font-size-18" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Reject
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     </>
    //   ),
    // },
  ]

  const toggle = () => {
    if (modal) {
      setModal(false)
      setOrder(null)
    } else {
      setModal(true)
    }
  }

  var node = useRef()
  var newUserTableRef = useRef()
  const onPaginationNewUserTablePageChange = page => {
    if (
      newUserTableRef &&
      newUserTableRef.current &&
      newUserTableRef.current.props &&
      newUserTableRef.current.props.pagination &&
      newUserTableRef.current.props.pagination.options
    ) {
      newUserTableRef.current.props.pagination.options.onPageChange(page)
    }
  }

  var userTableRef = useRef()
  const onPaginationUserTablePageChange = page => {
    if (
      userTableRef &&
      userTableRef.current &&
      userTableRef.current.props &&
      userTableRef.current.props.pagination &&
      userTableRef.current.props.pagination.options
    ) {
      userTableRef.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = selectedUsers => {
    setSelectedUsers(selectedUsers)
    setDeleteModal(true)
  }

  const handleDeleteUsers = () => {
    if (selectedUsers.id) {
      dispatch(onDeleteUsers(selectedUsers.id))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]
  const [activeTab1, setactiveTab1] = useState("5")
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)

  const [col5, setcol5] = useState(true)
  const [col6, setcol6] = useState(true)
  const [col7, setcol7] = useState(true)

  const [col8, setcol8] = useState(true)
  const [col9, setcol9] = useState(true)
  const [col10, setcol10] = useState(false)
  const [col11, setcol11] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
  }

  const t_col5 = () => {
    setcol5(!col5)
  }

  const t_col6 = () => {
    setcol6(!col6)
  }

  const t_col7 = () => {
    setcol7(!col7)
  }

  const t_col8 = () => {
    setcol6(!col6)
    setcol7(!col7)
  }

  const t_col9 = () => {
    setcol9(!col9)
    setcol10(false)
    setcol11(false)
  }

  const t_col10 = () => {
    setcol10(!col10)
    setcol9(false)
    setcol11(false)
  }

  const t_col11 = () => {
    setcol11(!col11)
    setcol10(false)
    setcol9(false)
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const handleTableChange = (type, { page, searchText }) => {
    setUsersList(
      users.filter(Users =>
        Object.keys(Users).some(key => {
          Users[key].toLowerCase().includes(searchText.toLowerCase())
        })
      )
    )
  }

  const { SearchBar } = Search;
  const processedUsers = (data) => { 
    const array = data.map(user => {
      const name = user.role === "legalrepresent"
        ? user?.userdetail?.userlrdetail === undefined ||
          user?.userdetail?.userlrdetail.length === 0
          ? "-"
          : user?.userdetail?.userlrdetail[0]?.companyname
        : user?.userdetail?.firstname === undefined ||
          user?.userdetail?.firstname === null
        ? "-"
        : `${user?.userdetail?.firstname} ${user?.userdetail?.lastname}`;
    
      return {
        ...user,
        name,
      };
    });
    return array;
  }
  
  // console.log(processedUsers);
  
  function onColumnMatch({
    searchText,
    value,
    column,
    row
  }) {
    // onPaginationNewUserTablePageChange(1);
    if(searchText === "") {
      return true;
    } else if(searchText.length > 0) {
      return (row.name).toLowerCase().includes(searchText.toLowerCase());
    }
  }

  const handleSearchChange = filterVal => {
    setSearchInput(filterVal);
    onPaginationNewUserTablePageChange(1); // Reset to the first page
  };

  const MySearch = (props) => {
    console.log('props', props);
    // let input;
    const handleSearch = (value) => {
      console.log('input.value', value)
      // props?.onSearch(input.value);
    };
    return (
      <div>
        <input
          className="form-control"
          // style={ { backgroundColor: 'pink' } }
          // ref={ n => input = n }
          type="text"
          onChange={(e) => handleSearch(e.target.value)}
        />
        {/* <button className="btn btn-warning" onClick={ handleClick }>Click to Search!!</button> */}
      </div>
    );
  };

  return (
    <React.Fragment>
      {/* <UserModal isOpen={modal1} toggle={toggleViewModal} />*/}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUsers}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <MetaTags>
          <title>Users</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="Users" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="6">
                      <CardTitle className="mb-4">New User Request</CardTitle>
                    </Col>
                    <Col sm="6">
                      <div className="text-sm-end">
                        <Link
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2 btn btn-primary"
                          //onClick={handleOrderClicks}
                          to="/onboard-users"
                        >
                          <i className="mdi mdi-plus me-1" />
                          Onboard Users
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  {/* {newUsers && newUsers.length > 0 ? ( */}
                    <PaginationProvider
                      pagination={paginationFactory(newUsersPageOptions)}
                      keyField="id"
                      columns={newUsersColumns(toggle)}
                      data={processedUsers(newUsers)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={processedUsers(newUsers)}
                          columns={newUsersColumns(toggle)}
                          bootstrap4
                          search={ {
                            searchFormatted: true,
                            onColumnMatch,
                            afterSearch: () => onPaginationNewUserTablePageChange(1),
                          } }
                          // search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      {/* <MySearch { ...toolkitProps.searchProps } /> */}
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                {/* <Col sm="12">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      Add New User
                                    </Button>
                                  </div>
                                </Col>*/}
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      ref={newUserTableRef}
                                      noDataIndication="No users available"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  {/* ) : (
                    <div>No New Usres Request</div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Existing User Details</CardTitle>
                  <Nav pills className="navtab-bg nav-justified mb-2">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "5",
                        })}
                        onClick={() => {
                          toggle1("5")
                        }}
                      >
                        All
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "6",
                        })}
                        onClick={() => {
                          toggle1("6")
                        }}
                      >
                        Investor
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "7",
                        })}
                        onClick={() => {
                          toggle1("7")
                        }}
                      >
                        Advisor
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "8",
                        })}
                        onClick={() => {
                          toggle1("8")
                        }}
                      >
                        Company
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(allUsersPageOptions)}
                            keyField="id"
                            columns={usersColumns(toggle)}
                            data={processedUsers(allUsers)}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={processedUsers(allUsers)}
                                columns={usersColumns(toggle)}
                                bootstrap4
                                search={ {
                                  searchFormatted: true,
                                  onColumnMatch,
                                  afterSearch: () => onPaginationUserTablePageChange(1),
                                } }
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar {...toolkitProps.searchProps} />
                                            {/* <MySearch { ...toolkitProps.searchProps } /> */}
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            noDataIndication="No users available"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(
                              investorUsersPageOptions
                            )}
                            keyField="id"
                            columns={usersColumns(toggle)}
                            data={investorsUsers}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={investorsUsers}
                                columns={usersColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            onTableChange={handleTableChange}
                                            noDataIndication="No users available"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(
                              advisorUsersPageOptions
                            )}
                            keyField="id"
                            columns={usersColumns(toggle)}
                            data={advisorsUsers}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={advisorsUsers}
                                columns={usersColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            noDataIndication="No users available"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="8">
                      <Row>
                        <Col sm="12">
                          <PaginationProvider
                            pagination={paginationFactory(
                              companyUsersPageOptions
                            )}
                            keyField="id"
                            columns={usersColumns(toggle)}
                            data={companyUsers}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="id"
                                data={companyUsers}
                                columns={usersColumns(toggle)}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="id"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            ref={node}
                                            noDataIndication="No users available"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                <CardTitle className="h4">Existing User</CardTitle>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={usersColumns(toggle)}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={users}
                        columns={usersColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>  */}
        </Container>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
  /* onAddNewUsers: PropTypes.func,
  onDeleteUsers: PropTypes.func,
  onUpdateUsers: PropTypes.func, */
}

export default Users
